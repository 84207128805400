<template>
  <div class="admin-menu-posts">
    <div class="admin-menu-items AdminMenuVerifications">
      <div class="admin-menu-items-content">
        <div
          :class="`admin-menu-item ${mkLink('admin=verifications-home', 'on')}`"
          @click="goFilter({ view: 'verifications', admin: 'verifications-home' }, 'admin')"
        >
          <span>{{ $locale["documents"] }}</span>
          <span class="itemBubble" v-if="documentsByVerify">{{ documentsByVerify }}</span>
        </div>
        <div
          :class="`admin-menu-item ${mkLink('admin=documents-home', 'on')}`"
          @click="goFilter({ view: 'verifications', admin: 'documents-home' }, 'admin')"
        >
          <span>{{ $locale["profile"] }}</span>
          <span class="itemBubble" v-if="profilesByVerify">{{ profilesByVerify }}</span>
        </div>
        <div
          :class="`admin-menu-item ${mkLink('admin=creators-home', 'on')}`"
          @click="goFilter({ view: 'verifications', admin: 'creators-home' }, 'admin')"
        >
          <span>{{ $locale["creators"] }}</span>
          <span class="itemBubble" v-if="creatorsByVerify">{{ creatorsByVerify }}</span>
        </div>
        <div
          :class="`admin-menu-item ${mkLink('admin=complaints-home', 'on')}`"
          @click="goFilter({ view: 'verifications', admin: 'complaints-home' }, 'admin')"
        >
          <span>{{ $locale["words"]["complaints"] }}</span>
          <span class="itemBubble" v-if="complaintsByVerify">{{ complaintsByVerify }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goFilter: function(filter, path) {
      const currentPath = path ? path : this.$creatorFeed ? this.$creatorFeed.user : "/";
      this.$router.push({ path: currentPath, query: filter }).catch(() => {});
      this.$global.scrollToEl({ el: ".main-feed", topgap: 60 });
      this.$emit("filter");
    },
  },
  computed: {
    tabs: function() {
      return [
        { title: "Verifications", icon: "", event: "verify", selected: this.$route.query.view === "verifications" },
        { title: "Others", icon: "", event: "others", selected: this.$route.query.view === "others" },
      ];
    },
    documentsByVerify: function() {
      return this.$store.state.counter.documentsByVerify;
    },
    profilesByVerify: function() {
      return this.$store.state.counter.profilesByVerify;
    },
    creatorsByVerify: function() {
      return this.$store.state.counter.creatorsByVerify;
    },
    complaintsByVerify: function() {
      return this.$store.state.counter.complaintsByVerify;
    },
  },
};
</script>
